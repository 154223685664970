@font-face {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  src: url("fontawesome-webfont.58488e7e.woff2") format("woff2"), url("fontawesome-webfont.ed962b83.woff") format("woff");
}

.glyph {
  font-family: FontAwesome;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #222;
  text-align: justify;
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
  font-family: Open Sans, Arial;
  font-size: 10px;
}

@media (width >= 850px), (height >= 850px) {
  body {
    font-size: 14px;
  }
}

@media (width >= 950px) and (height >= 850px) {
  body {
    font-size: 15px;
  }
}

a {
  color: #0e9dfe;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1, h2, h3 {
  font-weight: normal;
}

h1 {
  font-size: 200%;
}

h2 {
  font-size: 160%;
}

h3 {
  font-size: 140%;
}

h4 {
  font-size: 100%;
}

button, input, textarea {
  font-family: inherit;
  font-size: 90%;
}

input, textarea {
  border: 1px solid #ccc;
  width: 250px;
  padding: 8px;
}

iframe {
  width: 100%;
}

.preloadImage {
  height: 0;
}

.hide {
  display: none;
}

.logo {
  font-family: Cabin, Open Sans, Arial;
  line-height: 100%;
}

.logo > .italic {
  font-style: italic;
  font-weight: bold;
}

a.logo {
  color: inherit;
  text-decoration: none;
  display: block;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.small {
  font-size: 30%;
  line-height: 100%;
}

h3 .small {
  font-size: 55%;
}

main-site > .no-javascript {
  text-align: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 1%;
  display: flex;
}

main-site > .no-javascript h2 {
  color: #fe620e;
}

main-site > .no-javascript .logo {
  color: #ccc;
  margin-bottom: 10vh;
  font-size: 300%;
}

header {
  z-index: 10;
  color: #f8f8f8;
  text-align: center;
  position: relative;
}

header .logo {
  padding: 2vh 0;
  font-size: 400%;
}

header nav {
  margin-top: 1vh;
}

header nav > a {
  color: #fff;
  margin: 0 1.2vw;
  padding: 0 .5em;
  display: inline-block;
}

header nav > a:before {
  content: "« ";
}

header nav > a:after {
  content: " »";
}

header nav > a:hover {
  color: #0e9dfe;
  background-color: #222222b3;
  text-decoration: none;
  animation: 1s color;
}

header.fixed {
  visibility: hidden;
  z-index: 100;
  width: 100%;
  padding: 0 0 .3em;
  position: fixed;
  top: 0;
}

header.fixed.visible {
  visibility: visible;
  background-color: #222222f2;
  transition: background-color 1s;
}

header.fixed nav {
  margin-top: 0;
}

header.fixed nav > a:hover {
  background-color: inherit;
}

header.fixed .logo {
  padding: 0;
  font-size: 250%;
}

header.fixed .logo .small {
  vertical-align: middle;
  margin-left: .5em;
  display: inline-block;
}

.splash {
  color: #f8f8f8;
  background-color: #222;
  min-height: 100vh;
  position: relative;
}

.splash .image-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.splash .image-container > .image {
  background: url("coffee_computer_notes.20fc2e85.jpg") center / cover;
  height: 100%;
  animation-name: slideshow, kenburns;
  animation-duration: 39s, 13s;
  animation-timing-function: step-end, ease;
  animation-iteration-count: infinite, infinite;
  animation-direction: normal, normal;
  animation-play-state: paused;
  animation-delay: 10ms;
  animation-fill-mode: none, none;
}

@keyframes slideshow {
  0% {
    background-image: url("coffee_computer_notes.20fc2e85.jpg");
  }

  33.3% {
    background-image: url("cleveland-skyline.4e1b53b8.jpg");
  }

  66.7% {
    background-image: url("laptop-whiteboard.2f35214f.jpg");
  }
}

.splash .image-container > .pixel-overlay {
  background: #0009 url("pixel-overlay.6153be5a.png");
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.splash > .center {
  z-index: 10;
  text-align: center;
  margin: 6em 10% 0;
  position: relative;
}

.splash > .center > h1 {
  margin: 8vh 0;
  font-size: 300%;
  font-weight: lighter;
}

@media (width >= 800px) and (height >= 650px) {
  .splash > .center > h1 {
    font-size: 400%;
  }
}

@media (width >= 950px) and (height >= 850px) {
  .splash > .center > h1 {
    font-size: 500%;
  }
}

.splash > .center > p {
  text-align: justify;
  margin: 1em 20%;
}

.splash > .center > .buttons {
  margin-top: 5em;
  font-size: 120%;
}

.splash > .center > .buttons > a {
  color: #f8f8f8;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  margin: 1em;
  padding: .9em 2em;
  display: inline-block;
}

.splash > .center > .buttons > a:hover {
  background-color: #fe620ecc;
}

@media only screen and (orientation: portrait) {
  .splash > .center {
    margin-top: 10vh;
  }

  .splash > .center > p {
    margin: 1em 10%;
  }
}

section > * {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 40px;
}

section.fold {
  min-height: 100vh;
}

#about-us .team {
  text-align: center;
}

#about-us .team .person > img {
  border-radius: 3%;
  min-width: 150px;
  max-width: 35vw;
  max-height: 35vh;
}

#about-us .team .icons {
  margin-top: 1em;
}

#about-us .team .icons > a {
  color: #222;
  margin-right: .5em;
  padding: .3em;
  font-family: FontAwesome;
  font-size: 200%;
}

#about-us .team .icons > a:hover {
  color: #f8f8f8;
  background-color: #222;
  text-decoration: none;
}

#about-us .team .icons > .github:before {
  content: "";
}

#about-us .team .icons > .linked-in:before {
  content: "";
}

#about-us .team .icons > .google:before {
  content: "";
}

#about-us .team .hovered {
  margin-top: 1em;
  font-style: italic;
}

#about-us .name {
  font-weight: bold;
}

#about-us .title {
  font-style: italic;
}

#about-us .description {
  text-align: justify;
}

#our-process {
  background-color: #f8f8f8;
}

#our-process .timeline {
  position: relative;
}

#our-process .timeline .line {
  border-left: 3px solid #222;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
}

#our-process .timeline .step {
  text-align: right;
  width: 50%;
}

#our-process .timeline .step h3 {
  margin: 0;
  padding: 10px 0;
  display: inline-block;
}

#our-process .timeline .step img {
  background-color: #f8f8f8e6;
  border: 3px solid #222;
  padding: 5px;
}

#our-process .timeline .step p {
  margin: 0;
  padding: 0 20px 20px;
}

#our-process .timeline .step:hover {
  background-color: #2222221a;
  transition: background-color .2s;
}

#our-process .timeline .step:nth-child(odd) h3:after {
  content: "";
  font-family: FontAwesome;
  font-size: 16px;
  position: relative;
  left: .5em;
}

#our-process .timeline .step:nth-child(odd) p {
  text-align: left;
  margin-right: 1vw;
}

#our-process .timeline .step:nth-child(odd) p > img {
  float: right;
  margin-left: 1em;
}

#our-process .timeline .step:nth-child(2n) {
  text-align: left;
  margin-left: 50%;
}

#our-process .timeline .step:nth-child(2n) h3:before {
  content: "";
  font-family: FontAwesome;
  font-size: 16px;
  position: relative;
  left: -.32em;
}

#our-process .timeline .step:nth-child(2n) p {
  margin-left: 1vw;
}

#our-process .timeline .step:nth-child(2n) p > img {
  float: left;
  margin-right: 1em;
}

#services {
  background-color: #eee;
}

#services > .content > .italic {
  text-align: center;
  margin-top: 1em;
  font-size: 80%;
}

#services > .content > .list {
  flex-wrap: wrap;
  display: flex;
}

#services > .content > .list > service {
  text-align: center;
  border-right: 1px solid #222;
  flex-grow: 1;
  padding: 0 .5em;
}

#services > .content > .list > service:last-child {
  border-right: none;
}

#services > .content > .list > service > .glyph {
  text-align: center;
  font-size: 500%;
}

#services > .content > .list > service > .title {
  padding: .6em 0;
}

#services > .content > .list > service:hover {
  cursor: pointer;
  color: #f8f8f8;
  background-color: #222;
  transition: background-color .2s;
}

#services > .content > .list > service.active {
  color: #f8f8f8;
  background-color: #fe620e;
}

#services > .content > .descriptions {
  border-left: 2px solid #fe620e;
  border-right: 2px solid #fe620e;
  margin-top: 3em;
  padding: 1px 2em;
}

#services > .content > .descriptions > div:not([hidden]) {
  animation: .4s fade-in;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#services > .content > .descriptions img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 30vh;
}

#portfolio {
  color: #222;
  background-color: #7cc4f5;
}

#portfolio a {
  color: #222;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

#portfolio a:hover {
  text-decoration: underline;
}

#portfolio .project:not(:last-child) {
  border-bottom: 2px dotted #f8f8f8;
  margin-bottom: 2em;
  padding-bottom: 1em;
}

#portfolio .slideshow {
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  display: flex;
}

#portfolio .slideshow button {
  cursor: pointer;
  background: #fff;
  border: none;
  height: 2em;
  font-size: 200%;
  font-weight: bold;
}

#portfolio .slideshow button:disabled {
  visibility: hidden;
}

#portfolio .figures {
  scroll-behavior: smooth;
  display: flex;
  overflow: auto;
}

#portfolio .figures figure {
  margin: 1em;
}

#portfolio .figures figure:first-child {
  margin-left: 0;
}

#portfolio .figures figure:last-child {
  margin-right: 0;
}

#portfolio .figures figure img {
  max-width: 903px;
}

#quotes {
  color: #f8f8f8;
  background-color: #6b3c7c;
}

#quotes blockquote {
  margin: 0;
}

#quotes .quote {
  font-size: 160%;
  font-style: italic;
  display: flex;
}

#quotes .quote:before, #quotes .quote:after {
  content: open-quote;
  padding: 0 .2em;
  font-size: 200%;
  line-height: 0;
}

#quotes .quote:after {
  content: close-quote;
  align-self: flex-end;
}

#quotes .quotee {
  justify-content: right;
  align-items: center;
  margin-top: 2em;
  display: flex;
}

#quotes .quotee:before {
  content: "— ";
}

#quotes .quotee img {
  border-radius: 50%;
  width: 3em;
  height: 3em;
  padding: .5em;
}

#contact-us {
  color: #f8f8f8;
  background: #000 url("night-launch.d2a8477a.jpg") top / cover no-repeat;
  min-height: 90vh;
}

#contact-us .message {
  color: #f8f8f8;
  background-color: #6b3c7ce6;
  margin: 2em;
  padding: 1em;
  font-weight: bold;
  display: inline-block;
}

#contact-us .message.error {
  color: #222;
  background-color: #fe620ee6;
}

#contact-us .contact {
  text-align: center;
}

#contact-us .contact a {
  color: #f8f8f8;
  display: block;
}

#contact-us .contact a:hover {
  color: #0e9dfe;
}

#contact-us .contact .email:before, #contact-us .contact .phone:before, #contact-us .contact .address:before {
  margin-right: .5em;
  font-family: FontAwesome;
}

#contact-us .contact .email:before {
  content: "";
}

#contact-us .contact .phone:before {
  content: "";
}

#contact-us .contact .address:before {
  content: "";
}

#contact-us .contact hr {
  border: none;
  border-top: 1px solid #f8f8f8;
  height: 0;
  margin: 4vh 0;
}

#contact-us .grid {
  justify-content: space-between;
  display: flex;
}

@media only screen and (width <= 550px) {
  #contact-us .grid {
    display: block;
  }

  #contact-us .contact {
    margin-top: 5vh;
  }
}

#contact-us form {
  flex-direction: column;
  min-width: 35%;
  display: flex;
}

#contact-us form label {
  margin-bottom: 1vh;
}

#contact-us form label input, #contact-us form label textarea {
  box-sizing: border-box;
  width: 100%;
}

#contact-us form button {
  background-color: #fe620e;
  border: 1px solid #ccc;
  padding: 8px;
  display: block;
}

#contact-us form button:hover {
  background-color: #e35001;
}

#job-postings {
  border-top: 5px solid #fe620e;
  border-bottom: 5px solid #fe620e;
}

#job-postings .post {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin-bottom: 3em;
  padding: 0 5em;
}

#job-postings .right {
  float: right;
}

footer {
  text-align: center;
  background-color: #eee;
  padding: .7em;
  font-size: 75%;
}

@keyframes kenburns {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  92% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: scale3d(1.3, 1.3, 1.3)translate3d(10%, 10%, 0);
  }

  100% {
    opacity: 0;
    transform: scale3d(1.6, 1.6, 1.6)translate3d(15%, 5%, 0);
  }
}

/*# sourceMappingURL=index.0b064928.css.map */
