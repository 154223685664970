$color-blue = #0e9dfe
$color-blue-light = #7cc4f5
$color-green = #0de764
$color-white = #f8f8f8
$color-gray = #ccc
$color-orange = #fe620e
$color-purple = #6b3c7c
$color-black = #222
$color-light-gray = #eee

// Font imports
@font-face
	font-family: "FontAwesome"
	font-weight: normal
	font-style: normal
	src: url('./static/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
		url('./static/fontawesome-webfont.woff?v=4.7.0') format('woff')
.glyph
	font-family: "FontAwesome"

// General CSS rules
html
	scroll-behavior: smooth
body
	background-color: $color-white
	color: $color-black
	font-family: "Open Sans", Arial
	font-size: 10px
	text-align: justify
	padding: 0
	margin: 0
	@media (min-width: 850px), (min-height: 850px)
		// Lock the font size for larger screens
		font-size: 14px
	@media (min-width: 950px) and (min-height: 850px)
		// Enlarge the font size a tiny bit for even larger screens
		font-size: 15px

a
	text-decoration: none
	color: $color-blue
	&:hover
		text-decoration: underline

h1, h2, h3
	font-weight: normal

h1
	font-size: 200%
h2
	font-size: 160%
h3
	font-size: 140%
h4
	font-size: 100%

button, input, textarea
	font-family: inherit
	font-size: 90%

input, textarea
	padding: 8px
	width: 250px
	border: 1px solid #ccc

iframe
	width: 100%

// General classes
.preloadImage
	height 0
.hide
	display none
.logo
	font-family: "Cabin", "Open Sans", Arial
	line-height: 100%
	> .italic
		font-style: italic
		font-weight: bold
a.logo
	display: block
	color: inherit
	text-decoration: none

.bold
	font-weight: bold
.italic
	font-style: italic

.small
	font-size: 30%
	line-height: 100%

h3 .small
	font-size 55%

// No JavaScript
main-site > .no-javascript
	display: flex
	flex-direction: column
	align-items: center
	height: 100vh
	text-align: center
	padding: 1%
	h2
		color: $color-orange
	.logo
		color: #ccc
		font-size: 300%
		margin-bottom: 10vh

// Header displays iCompute logo and main navigation
header
	// Position on top of splash screen
	position: relative
	z-index: 10
	color: $color-white
	text-align: center
	.logo
		padding: 2vh 0
		// Note: .logo is also a general class
		font-size: 400%
	nav
		margin-top: 1vh
		> a
			display: inline-block
			margin: 0 1.2vw
			padding: 0 0.5em
			&::before
				content: "« "
			&::after
				content: " »"
			color: white
			&:hover
				color: $color-blue
				background-color: rgba($color-black, 70%)
				animation: color 1s ease
				text-decoration: none
	// Special rules for when header is fixed to top of page
	&.fixed
		visibility: hidden
		&.visible
			visibility: visible
			background-color: rgba($color-black, 95%)
			// Note: only transition when "visible" class is added
			transition: background-color 1s
		position: fixed
		top: 0
		width: 100%
		z-index: 100
		padding: 0 0 0.3em 0
		nav
			margin-top: 0
		// Change nav link colors
		nav > a
			&:hover
				background-color: inherit
		// Reduce logo size; collapse to single line
		.logo
			padding: 0
			font-size: 250%
			.small
				vertical-align: middle
				display: inline-block
				margin-left: 0.5em

// Splash screen, implementing a still-image slideshow and "kenburns" effect
.splash
	background-color: $color-black
	color: $color-white
	// Ensure splash is at least .image-container height
	min-height: 100vh
	// `position: relative` allows the image-container to remain in the flow,
	// so `height: 100%` works on the .image-container!
	position: relative
	// Position .image-container at top and extend the height of the viewport
	.image-container
		position: absolute
		top: 0
		width: 100%
		height: 100%
		// kenburns effect causes scaling; thus, overflow should be hidden
		overflow: hidden
		// .image is an element whose background image is the slideshow
		> .image
			animation: slideshow 39s infinite step-end, kenburns 13s infinite
			// Pause the animation until images are preloaded via JavaScript
			animation-play-state: paused
			// Use tiny animation delay to allow background-image to be
			// displayed until animation is started
			animation-delay: 10ms
			// Image height should match parent height and background-size should cover
			height: 100%
			background: url('./static/images/splash/coffee_computer_notes.jpg') center/cover
			@keyframes slideshow
				0%
					// https://www.pexels.com/photo/people-notes-meeting-team-7095/
					background-image: url('./static/images/splash/coffee_computer_notes.jpg')
				33.3%
					// https://localtvwjw.files.wordpress.com/2012/06/cleveland-wallpaper.jpg
					// http://fox8.com/2012/06/20/pictures-we-3-cle/cleveland-wallpaper/
					background-image: url('./static/images/splash/cleveland-skyline.jpg')
				66.7%
					// https://static.pexels.com/photos/7377/startup-photos.jpg
					background-image: url('./static/images/splash/laptop-whiteboard.jpg')
		> .pixel-overlay
			// Pixel overlay image is repeated and placed over the .image
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			// Image from https://www.transparenttextures.com
			background: url('./static/images/pixel-overlay.png') rgba(black, 0.6)
	// Center div is horizontally and "almost" vertically centered on the splash
	> .center
		position: relative
		z-index: 10
		text-align: center
		// Contains main site header, a short paragraph, and some buttons
		margin: 6em 10%
		margin-bottom: 0
		> h1
			font-weight: lighter
			font-size: 300%
			margin: 8vh 0
			@media (min-width: 800px) and (min-height: 650px)
				font-size: 400%
			@media (min-width: 950px) and (min-height: 850px)
				font-size: 500%
		> p
			text-align: justify
			margin: 1em 20%
		> .buttons
			margin-top: 5em
			font-size: 120%
			> a
				display: inline-block
				margin: 1em
				padding: 0.9em 2em
				color: $color-white
				border: 1px solid $color-white
				border-radius: 10px
				&:hover
					background-color: rgba($color-orange, 80%)
		@media only screen and (orientation: portrait)
			margin-top: 10vh
			> p
				margin: 1em 10%
@keyframes kenburns
	0%
		opacity: 0
	5%
		opacity: 1
	92%
		transform: scale3d(1.3, 1.3, 1.3) translate3d(10%, 10%, 0px)
		animation-timing-function: ease-in-out
		opacity: 1
	100%
		transform: scale3d(1.6, 1.6, 1.6) translate3d(15%, 5%, 0px)
		opacity: 0

section
	// Ensure that section content is limited in width
	> *
		padding: 60px 40px
		margin-left: auto
		margin-right: auto
		max-width: 950px
	// Extend section height to match viewport
	&.fold
		min-height: 100vh


#about-us
	.team
		text-align: center
		.person > img
			max-width: 35vw
			max-height: 35vh
			min-width: 150px
			border-radius: 3%
		.icons
			margin-top: 1em
		.icons > a
			color: $color-black
			font-size: 200%
			font-family: FontAwesome
			margin-right: 0.5em
			padding: 0.3em
			&:hover
				background-color: $color-black
				color: $color-white
				text-decoration: none
		.icons > .github::before
			content: "\f09b"
		.icons > .linked-in::before
			content: "\f0e1"
		.icons > .google::before
			content: "\f0d5"
		.hovered
			margin-top: 1em
			font-style: italic
	.name
		font-weight: bold
	.title
		font-style: italic
	.description
		text-align: justify

#our-process
	background-color: $color-white
	.timeline
		position: relative
		.line
			position: absolute
			top: 0
			left: 50%
			height: 100%
			border-left: 3px solid $color-black
		.step
			width: 50%
			text-align: right
			h3
				display: inline-block
				margin: 0
				padding: 10px 0
			img
				background-color: rgba($color-white, 90%)
				padding: 5px
				border: 3px solid $color-black
			p
				padding: 20px
				padding-top: 0
				margin: 0
			&:hover
				background-color: rgba($color-black, 0.1)
				transition: background-color 0.2s
		.step:nth-child(odd)
			h3::after
				position: relative
				font-size: 16px
				left: 0.5em
				font-family: FontAwesome
				content: "\f177\f111"
			p
				text-align: left
				margin-right: 1vw
				> img
					float: right
					margin-left: 1em
		.step:nth-child(even)
			text-align: left
			margin-left: 50%
			h3::before
				position: relative
				font-size: 16px
				left: -0.32em
				font-family: FontAwesome
				content: "\f111\f178"
			p
				margin-left: 1vw
				> img
					float: left
					margin-right: 1em

#services
	background-color $color-light-gray
	> .content
		> .italic
			text-align: center
			margin-top: 1em
			font-size: 80%
		> .list
			display: flex
			flex-wrap: wrap
			> service
				padding: 0 0.5em
				flex-grow: 1
				text-align: center
				border-right: 1px solid $color-black
				&:last-child
					border-right: none
				> .glyph
					font-size: 500%
					text-align: center
				> .title
					padding: 0.6em 0
				&:hover
					cursor: pointer
					background-color: $color-black
					color: $color-white
					transition: background-color 0.2s
				&.active
					background-color: $color-orange
					color: $color-white
		> .descriptions
			border-left: 2px solid $color-orange
			border-right: 2px solid $color-orange
			margin-top: 3em
			padding: 1px 2em
			> div:not([hidden])
				animation: fade-in 0.4s ease
				@keyframes fade-in
					from
						opacity: 0
					to
						opacity: 1
			img
				max-width: 100%
				max-height: 30vh
				width: auto
				height: auto

#portfolio
	background-color: $color-blue-light
	color: $color-black
	a
		color: $color-black
		text-decoration underline dotted
		&:hover
			text-decoration underline
	.project:not(:last-child)
		border-bottom: 2px dotted $color-white
		padding-bottom 1em
		margin-bottom 2em
	.slideshow
		user-select none
		display flex
		align-items center
		button
			font-weight bold
			font-size 200%
			border none
			background white
			height 2em
			cursor pointer
			&:disabled
				visibility hidden
	.figures
		display flex
		overflow auto
		scroll-behavior smooth
		figure
			margin 1em
			&:first-child
				margin-left 0
			&:last-child
				margin-right 0
			img
				max-width 903px


#quotes
	background-color: $color-purple
	color: $color-white
	blockquote
		margin: 0
	.quote
		font-style: italic
		display: flex
		font-size: 160%
		&:before, &:after
			content: open-quote
			font-size: 200%
			line-height: 0
			padding: 0 0.2em
		&:after
			content: close-quote
			align-self: flex-end
	.quotee
		margin-top: 2em
		display flex
		align-items center
		justify-content right
		&:before
			content: "—\00a0"
		img
			width 3em
			height 3em
			padding 0.5em
			border-radius 50%

#contact-us
	min-height: 90vh
	background: black url('./static/images/night-launch.jpg') no-repeat top/cover
	color: $color-white
	.message
		background-color: rgba($color-purple, 90%)
		font-weight: bold
		color: $color-white
		display: inline-block
		margin: 2em
		padding: 1em
		&.error
			background-color: rgba($color-orange, 90%)
			color: $color-black
	.contact
		text-align: center
		a
			display: block
			color: $color-white
			&:hover
				color: $color-blue
		.email::before, .phone::before, .address::before
			font-family: FontAwesome
			margin-right: 0.5em
		.email::before
			content: "\f0e0"
		.phone::before
			content: "\f095"
		.address::before
			content: "\f041"
		hr
			border: none
			border-top: 1px solid $color-white
			height: 0
			margin: 4vh 0
	.grid
		display: flex
		justify-content: space-between
	@media only screen and (max-width: 550px)
		.grid
			display: block
		.contact
			margin-top: 5vh
	form
		min-width: 35%
		display: flex
		flex-direction: column
		label
			input, textarea
				width: 100%
				box-sizing: border-box
			margin-bottom: 1vh
		button
			display: block
			padding: 8px
			border: 1px solid $color-gray
			background-color: $color-orange
			&:hover
				background-color: darken($color-orange, 15%)

#job-postings
	border-top: 5px solid $color-orange
	border-bottom: 5px solid $color-orange
	.post
		border-left: 1px solid $color-gray
		border-right: 1px solid $color-gray
		padding: 0 5em
		margin-bottom: 3em
	.right
		float: right

footer
	background-color: $color-light-gray
	text-align: center
	font-size: 75%
	padding: 0.7em
